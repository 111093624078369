import api from './api';
import { ProductCreateDto, ProductReadDto, ProductUpdateDto } from '../types';

const getProducts = async (): Promise<ProductReadDto[]> => {
	const response = await api.get('/api/Products');
	return response.data;
};

const getProductById = async (id: number): Promise<ProductReadDto> => {
	const response = await api.get(`/api/Products/${id}`);
	return response.data;
};

// Separate functions for create and update
export const createProduct = async (data: ProductCreateDto | FormData): Promise<ProductReadDto> => {
    return await handleProductRequest('POST', '/api/Products', data);
};

export const updateProduct = async (productId: number, data: ProductUpdateDto | FormData): Promise<ProductReadDto> => {
    return await handleProductRequest('PUT', `/api/Products/${productId}`, data);
};

// Shared handler function for creating and updating products
const handleProductRequest = async (
    method: 'POST' | 'PUT',
    url: string,
    data: ProductCreateDto | ProductUpdateDto | FormData
): Promise<ProductReadDto> => {
    try {
        console.log(`Sending API Request for ${method === 'POST' ? 'Product Creation' : 'Product Update'}`, data);
        let response;
        if (data instanceof FormData) {
            response = await api.request({
                method,
                url,
                data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } else {
            response = await api.request({
                method,
                url,
                data,
            });
        }
        return response.data;
    } catch (error) {
        console.error(`Error during ${method === 'POST' ? 'product creation' : 'product update'}`, error);
        throw error;
    }
};


const deleteProduct = async (id: number): Promise<void> => {
	await api.delete(`/api/Products/${id}`);
};

export default {
	getProducts,
	getProductById,
	createProduct,
	updateProduct,
	deleteProduct,
};
