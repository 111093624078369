import React, { useState, useEffect } from 'react';
import {
	TextField,
	Button,
	Box,
	Snackbar,
	Alert,
	MenuItem,
	IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../services/api';

// Basic Product Form Component
const BasicProductForm: React.FC<{
	existingProduct?: any;
	onCancel: () => void;
}> = ({ existingProduct, onCancel }) => {
	const [productTitle, setProductTitle] = useState('');
	const [productDescription, setProductDescription] = useState('');
	const [price, setPrice] = useState('');
	const [costPrice, setCostPrice] = useState('');
	const [source, setSource] = useState('');
	const [categoryId, setCategoryId] = useState('');
	const [images, setImages] = useState<FileList | null>(null);
	const [existingImages, setExistingImages] = useState<string[]>([]);
	const [categories, setCategories] = useState<
		{ categoryId: number; categoryName: string }[]
	>([]);
	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState<string>('');
	const [openSnackbar, setOpenSnackbar] = useState(false);

	useEffect(() => {
		// Fetch categories from the API when component mounts
		const fetchCategories = async () => {
			try {
				const response = await api.get('/api/Categories');
				setCategories(response.data);
			} catch (err) {
				console.error('Error fetching categories:', err);
				setError('Failed to load categories. Please try again later.');
			}
		};

		fetchCategories();
	}, []);

	useEffect(() => {
		// Fetch product details if editing an existing product
		if (existingProduct && existingProduct.productId) {
			const fetchProduct = async () => {
				try {
					const response = await api.get(
						'/api/Products/${existingProduct.productId}'
					);
					const product = response.data;
					setProductTitle(product.productTitle || '');
					setProductDescription(product.productDescription || '');
					setPrice(product.price?.toString() || '');
					setCostPrice(product.costPrice?.toString() || '');
					setSource(product.source || '');
					setCategoryId(product.categoryId?.toString() || '');
					setExistingImages(product.imagePaths || []);
				} catch (err) {
					console.error('Error fetching product details:', err);
					setError('Failed to load product details. Please try again later.');
				}
			};

			fetchProduct();
		}
	}, [existingProduct]);

const handleSubmit = async (e: React.FormEvent) => {
	e.preventDefault();
	const formData = new FormData();
	formData.append('productTitle', productTitle);
	formData.append('productDescription', productDescription);
	formData.append('price', price);
	if (costPrice) {
		formData.append('costPrice', costPrice);
	}
	formData.append('source', source);
	if (categoryId) {
		formData.append('categoryId', categoryId);
	}

	// Add existing images as a comma-separated string
	if (existingImages.length > 0) {
		formData.append('ExistingImagesJson', existingImages.join(','));
	}

	// Add new images, if any
	if (images) {
		Array.from(images).forEach((image) => {
			formData.append('Images', image); // Ensure field name matches what backend expects
		});
	}

	try {
		if (existingProduct?.productId) {
			const response = await api.put(
				`/api/Products/${existingProduct.productId}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			console.log('Product updated successfully:', response.data);
			setSnackbarMessage('Product updated successfully!');
		} else {
			const response = await api.post('/api/Products',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
			console.log('Product created successfully:', response.data);
			setSnackbarMessage('Product created successfully!');
		}
		setSuccess(true);
		setOpenSnackbar(true);
	} catch (err) {
		console.error('Error creating/updating product:', err);
		setError('Failed to create/update product. Please try again.');
		setOpenSnackbar(true);
	}
};



	const handleDeleteImage = async (fileName: string) => {
		try {
			await api.delete(`/api/Images/${fileName}`);
			setExistingImages((prev) => prev.filter((img) => img !== fileName));
			setSuccess(true);
			setSnackbarMessage('Image deleted successfully!');
			setOpenSnackbar(true);
		} catch (err) {
			console.error('Error deleting image:', err);
			setError('Failed to delete image. Please try again.');
			setOpenSnackbar(true);
		}
	};

	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
		// Reload the form only when product update or creation is done successfully.
		if (
			success &&
			(snackbarMessage === 'Product updated successfully!' ||
				snackbarMessage === 'Product created successfully!')
		) {
			window.location.reload();
		}
	};

	return (
		<Box
			component="form"
			onSubmit={handleSubmit}
			sx={{ mt: 2 }}>
			{error && <p style={{ color: 'red' }}>{error}</p>}
			<TextField
				label="Product Title"
				value={productTitle}
				onChange={(e) => setProductTitle(e.target.value)}
				fullWidth
				margin="normal"
				required
			/>
			<TextField
				label="Product Description"
				value={productDescription}
				onChange={(e) => setProductDescription(e.target.value)}
				fullWidth
				margin="normal"
				multiline
				rows={4}
			/>
			<Box
				sx={{
					display: 'flex',
					gap: 2, // Adds space between the text fields
					flexWrap: 'wrap',
				}}>
				<TextField
					label="Price"
					value={price}
					onChange={(e) => setPrice(e.target.value)}
					type="number"
					fullWidth
					margin="normal"
					required
					sx={{ flex: 1 }} // Allows both text fields to take up equal space
				/>
				<TextField
					label="Cost Price"
					value={costPrice}
					onChange={(e) => setCostPrice(e.target.value)}
					type="number"
					fullWidth
					margin="normal"
					sx={{ flex: 1 }}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					gap: 2, // Adds space between the text fields
					flexWrap: 'wrap',
				}}>
				<TextField
					label="Source"
					value={source}
					onChange={(e) => setSource(e.target.value)}
					fullWidth
					margin="normal"
					required
					error={!source}
					helperText={!source ? 'Source is required' : ''}
					sx={{ flex: 1 }}
				/>
				<TextField
					select
					label="Category"
					value={categoryId}
					onChange={(e) => setCategoryId(e.target.value)}
					fullWidth
					margin="normal"
					required
					sx={{ flex: 1 }}>
					{categories.map((category) => (
						<MenuItem
							key={category.categoryId}
							value={category.categoryId}>
							{category.categoryName}
						</MenuItem>
					))}
				</TextField>
			</Box>

			{/* Show existing images */}
			{existingImages.length > 0 && (
				<div>
					<p>Existing Images:</p>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						{existingImages.map((image, index) => (
							<div
								key={index}
								style={{ position: 'relative', marginRight: '10px' }}>
								<img
									src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image}`}
									alt={`Product Image ${index + 1}`}
									style={{ width: '100px', height: '100px' }}
								/>
								<IconButton
									color="secondary"
									onClick={() => handleDeleteImage(image)}
									style={{ position: 'absolute', top: 0, right: 0 }}>
									<DeleteIcon />
								</IconButton>
							</div>
						))}
					</div>
				</div>
			)}

			{/* Upload new images */}
			<input
				type="file"
				multiple
				accept="image/*"
				onChange={(e) => setImages(e.target.files)}
				style={{ marginTop: '1rem' }}
				disabled={existingImages.length >= 10}
			/>

			<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
				<Button
					type="submit"
					variant="contained"
					color="primary">
					{existingProduct ? 'Update Product' : 'Create Product'}
				</Button>
				<Button
					variant="outlined"
					color="secondary"
					onClick={onCancel}>
					Cancel
				</Button>
			</Box>

			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}>
				<Alert
					onClose={handleCloseSnackbar}
					severity={success ? 'success' : 'error'}
					sx={{ width: '100%' }}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default BasicProductForm;
