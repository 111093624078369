import React, { useEffect, useState } from 'react';
import { ProductReadDto } from '../../types';
import productService from '../../services/productService'; // Assuming this contains API calls
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	CircularProgress,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../common/ConfirmDialog';
import BasicProductForm from './BasicProductForm';

const Products: React.FC = () => {
	// 1. Set up state for products
	const [products, setProducts] = useState<ProductReadDto[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [openForm, setOpenForm] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<ProductReadDto | null>(
		null
	);
	const [openConfirm, setOpenConfirm] = useState<boolean>(false);
	const [productToDelete, setProductToDelete] = useState<number | null>(null);

	// 2. Fetch Products initially
	const fetchProducts = async () => {
		try {
			setLoading(true);
			const data = await productService.getProducts(); // Assuming this fetches products from API
			setProducts(data);
		} catch (error) {
			console.error('Failed to fetch products:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchProducts();
	}, []);

	// 3. Handle Add/Edit Product Submission
	const handleFormSubmit = async (product: ProductReadDto | null) => {
		if (!product) {
			console.error('No product provided to handleFormSubmit.');
			return;
		}

		try {
			if (product.productId) {
				// Edit existing product
				const updatedProducts = products.map((p) =>
					p.productId === product.productId ? product : p
				);
				setProducts(updatedProducts);
			} else {
				// Add new product
				const newProduct = await productService.createProduct(product as any);
				setProducts((prevProducts) => [...prevProducts, newProduct]);
			}
		} catch (error) {
			console.error('Error handling form submit:', error);
		}

		setOpenForm(false);
	};

	// 4. Handle Delete Product
	const handleDelete = (id: number) => {
		setProductToDelete(id);
		setOpenConfirm(true);
	};

	const confirmDelete = async () => {
		if (productToDelete !== null) {
			try {
				await productService.deleteProduct(productToDelete);
				setProducts(products.filter((p) => p.productId !== productToDelete));
			} catch (error) {
				console.error('Failed to delete product:', error);
			} finally {
				setOpenConfirm(false);
				setProductToDelete(null);
			}
		}
	};

	// Loading indicator while data is being fetched
	if (loading) return <CircularProgress />;

	return (
		<>
			<Button
				variant="contained"
				color="primary"
				onClick={() => {
					setSelectedProduct(null);
					setOpenForm(true);
				}}
				sx={{ mb: 2 }}>
				Add Product
			</Button>
			<TableContainer component={Paper}>
				<Table aria-label="products table">
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Product Name</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{products.map((product) => (
							<TableRow key={product.productId}>
								<TableCell>{product.productId}</TableCell>
								<TableCell>{product.productTitle}</TableCell>
								<TableCell align="right">
									<IconButton
										color="primary"
										onClick={() => {
											setSelectedProduct(product);
											setOpenForm(true);
										}}>
										<EditIcon />
									</IconButton>
									<IconButton
										color="secondary"
										onClick={() => handleDelete(product.productId)}>
										<DeleteIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
						{products.length === 0 && (
							<TableRow>
								<TableCell
									colSpan={3}
									align="center">
									No products found.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			{/* Product Form Dialog */}
			<Dialog
				open={openForm}
				onClose={() => setOpenForm(false)}>
				<DialogTitle>
					{selectedProduct ? 'Edit Product' : 'Add Product'}
				</DialogTitle>
				<DialogContent>
					<BasicProductForm
						existingProduct={selectedProduct} // Pass selectedProduct here
						onCancel={() => setOpenForm(false)}
					/>
				</DialogContent>
			</Dialog>

			{/* Confirm Delete Dialog */}
			<ConfirmDialog
				open={openConfirm}
				title="Delete Product"
				content="Are you sure you want to delete this product?"
				onConfirm={confirmDelete}
				onCancel={() => setOpenConfirm(false)}
			/>
		</>
	);
};

export default Products;
